/* stylesheets */
import "../stylesheets/top.css";

/* images */
import sample_image      from "../assets/images/sample.png";
import ice_0             from "../assets/images/top/ice_0.jpg";
import ice_1             from "../assets/images/top/ice_1.jpg";
import ice_2             from "../assets/images/top/ice_2.jpg";
import spgcnt_bldg_0     from "../assets/images/top/spgcnt_bldg_0.jpg";
import spgcnt_bldg_1     from "../assets/images/top/spgcnt_bldg_1.jpg";
import kanamenomori_bldg from "../assets/images/top/kanamenomori_bldg.jpg";
import kanamenomori_0    from "../assets/images/top/kanamenomori_0.jpg";
import kanamenomori_1    from "../assets/images/top/kanamenomori_1.jpg";
import kanamenomori_2    from "../assets/images/top/kanamenomori_2.jpg";
import kanamenomori_3    from "../assets/images/top/kanamenomori_3.jpg";
import kanamenomori_4    from "../assets/images/top/kanamenomori_4.jpg";
import kanamenomori_5    from "../assets/images/top/kanamenomori_5.jpg";

export default function Top() {
  return(
    <>
      <h1 className="title-sm">
        <p className="text-lg">地球・街・経済 すべてに良い循環を</p>
        <p>かなめのもり</p>
      </h1>

      <div className="mt-0 mx-0 p-0 items-start justify-items-start">
        <h1 className="title-lg bg-gray-50">
          <p className="text-lg">地球・街・経済 すべてに良い循環を</p>
          <p>かなめのもり</p>
        </h1>

        <img className="m-0 p-0" src={ kanamenomori_bldg } alt="" width="100%" />
      </div>

      <article className="flex flex-col">
        <div className="flex flex-col items-center">
          <p>森と同じ気持ちよさを街の中でも。</p>
          <p>「いるだけで、ただ気持ちがいい」「本当の心地よさ」を体験できる場所として、武蔵小山ショッピングセンタービルがかなめのもりに生まれ変わります。</p>
          <p>建設中も土中環境の水と空気の導線を守り涵養し、屋上とテラスの緑地もそのままで自然の雑木林に成長するようにしつらえました。</p>
          <p>今は、どんぐりから芽吹いて１年目の若木たちですが、光と風と人々のやさしさに包まれて、美しい木々へと成長し、私たちに心地よさを運んできます。</p>
          <p>かなめのもりは、人と自然との調和する気持ちよさを提供し、この気持ちよさが、地球・街・経済すべての良い循環につながっていくと考えています。</p>
        </div>

        <div className="flex flex-col items-center justify-center">
          <span className="w-auto">
            <p>かなめのもりは、本館（かなめのもりのビル）、かなめいなり神社と社務所の施設で構成されています。</p>

            <img className="m-auto" src={ sample_image } height="30%" alt="" />

            <p>「かなめのもり」は2022年11月24日竣工</p>
            <p>地上2階建てで床面積は本館1823.86㎡・事務所32.03㎡</p>
          </span>
        </div>

        <div>
          <figure className="image_container">
            <span>
              <img src={ kanamenomori_0 } alt="" />
              <img src={ kanamenomori_1 } alt="" />
              <img src={ kanamenomori_2 } alt="" />
              <img src={ kanamenomori_3 } alt="" />
              <img src={ kanamenomori_4 } alt="" />
              <img src={ kanamenomori_5 } alt="" />
            </span>
            <figcaption>第3期 かなめのもり</figcaption>
          </figure>
        </div>

        <div>
          <h2>沿革</h2>

          <span>
            <h3>第1期 長谷川製氷冷蔵工場 (長谷川 要之助)</h3>

            <p>　長谷川氷室は明治中期に、一世長谷川要之助、駒井高等により、個人商店として創業しました</p>
            <p>　創業時の主な商品は天然氷です。現長野新幹線軽井沢駅のすぐ南に広大な製氷池を有し、天然氷の切り出しを行っていたほか、他の寒冷地からも仕入れを行っていました。</p>
            <br />
            <p>三十間堀と旧汐留貨物駅の水陸双方に便利な現在地に本店を構え、大正期に入ると、現東京都品川区荏原の地に鉄筋コンクリート造の製氷工場および冷凍倉庫を建設し、日産20トン（その後、増設して30トン）の氷を製造しました。</p>
            <br />
            <p>　次いで大正12年には資本金23万8千円の株式会社を設立しました。</p>
            <p>昭和の戦時中も戦災を免れ、工場近隣の仲買に販売するとともに、本店では銀座、新橋烏森の飲食店を顧客として自社製品の小売り販売を行いました。</p>
          </span>

          <span className="flex flex-col items-center">
            <figure className="image_container">
              <span>
                <img src={ ice_0 } alt="" />
                <img src={ ice_1 } alt="" />
                <img src={ ice_2 } alt="" />
              </span>
              <figcaption>第1期 長谷川製氷冷蔵工場・かなめ稲荷神社と長谷川要之助</figcaption>
            </figure>
          </span>

          <span>
            <h3>第2期 武蔵小山ショッピングセンタービル (長谷川 清衛)</h3>

            <p>　戦後、電気冷蔵庫の急速な普及により、特に家庭での氷の需要が激減したため、製氷業から撤退しました。</p>
            <p>昭和39年（1964）に工場の解体を開始、一時青空駐車場としたのち同40年（1965）、阪急共栄ストアー（後に阪急ファミリーストア）の誘致が決まり、1階スーパーマーケット、２階食堂街、3・4階マンションという構成のビルを建設しました。</p>
            <br />
            <p>平成15年（2003）5月、阪急ファミリーストアが撤退し、6月、東急ストアが開業しました。</p>
          </span>

          <span className="flex flex-col items-center">
            <figure className="image_container">
              <span>
                <img src={ spgcnt_bldg_0 } alt="" />
                <img src={ spgcnt_bldg_1 } alt="" />
              </span>
              <figcaption>第2期 武蔵小山ショッピングセンタービルとかなめ稲荷神社</figcaption>
            </figure>
          </span>

          <span>
            <h3>第3期 かなめのもり (井上 創)</h3>

            <p>令和元年（2019）本ビルを新耐震基準に適合させる方針を決定しました。</p>
            <p>2年間の準備期間の中、令和2年（2020）3月より2階 味の名店街各店は順次移転・閉店しました。</p>
            <p>令和2年　2021年5月16日をもって東急ストアパルム武蔵小山店が閉店し当ビルの営業が終了しました。</p>
            <br />
            <p>令和3年（2021）6月解体新築工事に着手しました。</p>
          </span>

          <span className="flex flex-col items-center">
            <figure className="image_container">
              <span>
                <img src={ sample_image } alt="" />
                <img src={ sample_image } alt="" />
                <img src={ sample_image } alt="" />
                <img src={ sample_image } alt="" />
              </span>
              <figcaption>第3期 かなめのもり</figcaption>
            </figure>
          </span>
        </div>
      </article>
    </>
  );
}
