/* modules */
import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { AtSymbolIcon, InformationCircleIcon, LocationMarkerIcon, ViewListIcon } from "@heroicons/react/solid";

/* components */
import Top from "./Top";
import Access from "./Access";
import About from "./About";
import Information from "./Information";

/* stylesheets */
import "../stylesheets/tailwindcss.css";
import "../stylesheets/index.css";
import "../stylesheets/app.css";

export default function App() {
  return (
    <>
      <Router>
        {/* above tab */}
        <nav className="above-tab">
          <ul>
            <li><Link to="/">トップページ</Link></li>
            <li><Link to="/access">アクセス</Link></li>
            <li><Link to="/about">会社情報</Link></li>
            <li><Link to="/information">お知らせ</Link></li>
          </ul>
        </nav>

        {/* below tab */}
        <nav className="below-tab">
          <ul>
            <li className="below-tab-item">
              <Link to="/" className="below-tab-item">
                <ViewListIcon className="below-tab-icon" />
                <p>トップページ</p>
              </Link>
            </li>
            <li>
              <Link to="/access" className="below-tab-item">
                <LocationMarkerIcon className="below-tab-icon" />
                <p>アクセス</p>
              </Link>
            </li>
            <li>
              <Link to="/about" className="below-tab-item">
                <AtSymbolIcon className="below-tab-icon" />
                <p>会社情報</p>
              </Link>
            </li>
            <li>
              <Link to="/information" className="below-tab-item">
                <InformationCircleIcon className="below-tab-icon" />
                <p>お知らせ</p>
              </Link>
            </li>
          </ul>
        </nav>

        <div className="sm:hidden m-0 p-0 h-6" />

        <div className="main">
          <Switch>
            <Route exact path="/">
              <Top />
            </Route>
            <Route exact path="/access">
              <Access />
            </Route>
            <Route exact path="/about">
              <About />
            </Route>
            <Route exact path="/information">
              <Information />
            </Route>
          </Switch>
        </div>
      </Router>
    </>
  );
}
